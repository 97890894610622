<template>
  <div class="text-container" @mouseenter="mouse_enter_action" @mouseleave="mouse_leave_action">
    <div class="text wrap" :class="{ 'tooltip-visible': showTooltip }" >
      {{fullText}}
    </div>
    <div class="tooltip wrap" v-if="showTooltip">
      {{ fullText }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showTooltip: false, // 控制浮动窗口显示的数据属性
      fullText: "这是完整的文本内容，当鼠标悬浮在文本容器上时，这个浮动窗口会显示出来。 \n sdkgjdsfgjsg圣诞节公司的客户公司的韩国山 \n东化工是公司的结合公司的给合适的价格还是公司电话给", // 完整的文本内容
    };
  },
  methods:{
    mouse_enter_action(event){
       this.showTooltip = true;
    //    console.log("mouseenter")
    },

    mouse_leave_action(event){
        this.showTooltip =false;
        // console.log("mouseleave")
    },

  }
};
</script>

<style scoped>
.text-container {
  position: relative;
  display: inline-block;
}

.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* 根据实际行高调整 */
  max-height: 3em; /* 两行文本的最大高度 */
}

.tooltip {
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #d3d3d3;
  padding: 10px;
  z-index: 1000;
  max-width: 300px; /* 根据需要调整宽度 */
  white-space: normal;
  display: none; /* 默认不显示 */
}

.tooltip-visible {
  display: block; /* 鼠标悬浮时显示 */
}

.wrap{
    white-space: pre-wrap;
}
</style>